import { useState } from 'react';
import chevronDown from '../../../assets/icons/ic_chevron_down_black.svg';
import chevronUp from '../../../assets/icons/ic_chevron_up_black.svg';
import './FaQ.scss';

function FaQ(props) {
  const [index, setIndex] = useState(0);

  const onClickHandler = (idx) => {
    setIndex((prevIndex) => (prevIndex === idx ? null : idx));
  };

  return (
    <section className="faq-section">
      <div className="faq-title">자주 묻는 질문</div>
      <div className="faq-subcontainer">
        <div
          className="faq-question-container-first"
          onClick={() => onClickHandler(1)}
          style={{
            borderBottom: `1px solid ${index !== 1 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">연속혈당측정기(CGM)가 뭔가요?</div>
          <img src={index === 1 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 1 && (
          <div className="faq-answer-container">
            팔 뒤에 부착하는 500원 동전 크기의 작은 센서로 실시간으로 혈당을
            측정해주는 기기입니다. 식약처를 통해 안전성과 효과성이 입증
            되었습니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(2)}
          style={{
            borderBottom: `1px solid ${index !== 2 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">아프지는 않나요?</div>
          <img src={index === 2 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 2 && (
          <div className="faq-answer-container">
            피에서 측정하는 방식이 아니기 때문에 93%의 회원분들은 통증이 전혀
            없다고 말씀하셨습니다. 부착 반응이 궁금한 경우 유튜브에
            ‘연속혈당측정기’를 검색해보시면 영상을 확인해보실 수 있습니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(3)}
          style={{
            borderBottom: `1px solid ${index !== 3 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            샤워나 운동 등 일상생활에 문제는 없나요?
          </div>
          <img src={index === 3 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 3 && (
          <div className="faq-answer-container">
            샤워, 운동, 사우나, 여행 모두 문제 없이 하실 수 있습니다. 물
            속에서는 30분까지 있을 수 있습니다. 센서(CGM)는 한 번에 2주 동안
            부착하고 있는 기기이고, 2주 후에는 떼서 일반 쓰레기로 버리면 됩니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(4)}
          style={{
            borderBottom: `1px solid ${index !== 4 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            당뇨/임신성 당뇨가 있는 사람도 글루코핏 서비스를 이용해도 되나요?
          </div>
          <img src={index === 4 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 4 && (
          <div className="faq-answer-container">
            글루코핏은 의료적 조언이나 치료를 제공하는 서비스가 아닌 영양과 운동
            정보 피드백을 제공하는 서비스입니다. <br />
            <br />
            당뇨는 크게 약물치료와 행동교정 치료 두 가지를 동시에 하는 것이
            필요합니다. 약물 치료는 당뇨에 대한 근본적인 해결책이 아닌 혈당만
            낮추는 일시적인 치료인 경우가 많고 생활습관 교정을 하는 것이 인슐린
            저항성을 낮추는 근본적인 해결책입니다. 약물 치료를 통해 고혈당
            상태를 최소한으로 유지할 수 있도록 하고 꼭 생활습관 교정도 함께
            진행해주세요.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(5)}
          style={{
            borderBottom: `1px solid ${index !== 5 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">임산부인데 글루코핏 해도 괜찮나요?</div>
          <img src={index === 5 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 5 && (
          <div className="faq-answer-container">
            네 괜찮습니다. 이미 임신성 당뇨 환자 분들은 CGM을 부착하고 사용하고
            있고, 임신과 전혀 무관하게 사용할 수 있습니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(6)}
          style={{
            borderBottom: `1px solid ${index !== 6 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            연속혈당측정기(CGM) 사용 중 고장이 나거나 떨어지면 어떻게 되나요?
          </div>
          <img src={index === 6 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 6 && (
          <div className="faq-answer-container">
            글루코핏을 통해 구매한 센서는 글루코핏 고객센터로 문의 주시면
            원스톱으로 교환 및 A/S를 도와 드립니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(7)}
          style={{
            borderBottom: `1px solid ${index !== 7 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            센서(CGM)를 한 달만 붙이면 모든 음식을 다 실험할 수 있나요?
          </div>
          <img src={index === 7 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 7 && (
          <div className="faq-answer-container">
            똑같은 음식에 대해서도 각종 상황에 따라 혈당 반응이 다르게 나타나기
            때문에 한 달에 모든 음식을 테스트하는 것은 쉽지는 않습니다. 또한
            이전에 잘 맞았던 음식도 시간이 지나면서 몸이 바뀌면 잘 맞지 않게 될
            수 있습니다. 따라서 글루코핏에서는 계절이 바뀌는 3개월에 한 번 정도
            CGM을 부착하고 테스트 해보시는 것을 권장드리고 있습니다. 1년 이상
            장기간 글루코핏을 한 회원 분들은 고장 났던 대사가 건강하게 돌아오고
            완벽히 건강한 습관을 만들게 되어 90% 이상에서 요요가 없습니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(8)}
          style={{
            borderBottom: `1px solid ${index !== 8 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            글루코핏을 하면 어떤 변화를 기대할 수 있나요?
          </div>
          <img src={index === 8 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 8 && (
          <div className="faq-answer-container">
            1. &nbsp;&nbsp;나한테 어떤 음식이 살이 찌고 건강한지를 진짜 데이터를
            기반으로 확인할 수 있습니다. 획일적으로 ‘건강하다고 알려진’ 식단이
            아닌 나에게 살이 찌는 음식을 직접 눈으로 확인할 수 있습니다. <br />
            2. &nbsp;&nbsp;살이 찌는 음식을 무작정 끊는 것이 아니라, 똑같은
            음식도 더 건강하게 먹으면서 혈당을 조절하는 방법을 알게 되어 지속
            가능한 요요없는 식단관리가 가능합니다.
            <br />
            3. &nbsp;&nbsp;나에게 맞는 운동 종류와 시간, 최적의 간헐적 단식
            시간, 수면 시간 까지 알 수 있게 됩니다.
            <br />
            4. &nbsp;&nbsp;혈당 조절은 단순히 다이어트 뿐만 아니라 컨디션 개선,
            식곤증 개선, 피부 주름, 여드름 개선에도 효과가 있습니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(9)}
          style={{
            borderBottom: `1px solid ${index !== 9 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            연속혈당측정기(CGM)만 구매해서 사용하는 것과 무엇이 다른가요?
          </div>
          <img src={index === 9 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 9 && (
          <div className="faq-answer-container">
            연속혈당측정기는 혈당을 측정하기만 하는 용도의 기기입니다. 글루코핏
            회원이 되게 되면 혈당이 왜 올랐는지, 올라갔을 때는 어떻게 해야하는지
            의사 코치님과 인공지능의 분석과 피드백을 받아볼 수 있습니다. 또한
            커뮤니티 회원 분들과의 소통을 통해 다른 사람들은 똑같은 음식에
            어떻게 반응이 다른지 등 즐거운 실험을 하실 수 있습니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(10)}
          style={{
            borderBottom: `1px solid ${index !== 10 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            주문한 글루코핏 패키지는 언제 받을 수 있나요?
          </div>
          <img src={index === 10 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 10 && (
          <div className="faq-answer-container">
            구매일로부터 평일 기준 배송 2-4일정도가 소요됩니다. 배송 관련 문의는
            구매하신 아래 정보로 문의바랍니다. <br />
            글루코핏 카카오톡 채팅 상담 (카카오톡 검색: 글루코핏)
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(14)}
          style={{
            borderBottom: `1px solid ${index !== 14 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            해외에 거주하고 있어요. 해외 구매 및 사용이 가능한가요?
          </div>
          <img src={index === 14 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 14 && (
          <div className="faq-answer-container">
            해외 구매 및 사용은 불가합니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(11)}
          style={{
            borderBottom: `1px solid ${index !== 11 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">
            글루코핏 멤버십을 양도하거나 센서를 지인과 1개씩 사용해도 되나요?
          </div>
          <img src={index === 11 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 11 && (
          <div className="faq-answer-container">
            글루코핏 멤버십은 양도할 수 없습니다. 또한 첫 달 CGM을 2개 수령하신
            경우 지인에게 CGM 센서를 양도할 수는 있으나 지인의 경우 글루코핏
            회원이 아니기 때문에 글루코핏 서비스는 사용하실 수 없습니다.
          </div>
        )}
        <div
          className="faq-question-container"
          onClick={() => onClickHandler(12)}
          style={{
            borderBottom: `1px solid ${index !== 12 ? '#E0E4E4' : '#FFFFFF'}`,
          }}
        >
          <div className="faq-question">주의 및 권고사항</div>
          <img src={index === 12 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 12 && (
          <div className="faq-answer-container">
            <div className="strong">
              글루코핏은 ‘비의료 건강관리 서비스 가이드’에 맞춰 진행되는 서비스
              입니다. <br /> 서비스 제공 범위
            </div>
            • 혈당 조절을 위한 식이요법 및 식품군에 대한 설명 <br />
            • 생활습관(식사, 운동, 수면, 스트레스 등)에 대한 개선 및 상담 조언
            <br />
            <br />
            <div className="strong">
              당뇨에 대한 진단 및 치료, 치료에 대한 상담은 반드시 담당 주치의와
              상의하시길 권장드립니다.
            </div>
            <br />
            <div className="strong">
              [개인용체내연속혈당측정시스템 안전사용정보안]
            </div>
            • 연속 혈당측정기의 포장(라벨)이나 동봉된 문서를 확인하여
            의료기기(연속혈당측정기)의 사용 목적 및 사용 시 주의할 사항을 반드시
            숙지해야 합니다. <br />
            • 처음 연속혈당측정기를 사용하는 경우, 전문가의 상담 및 교육을 받아
            사용 방법 및 주의할 점(부착 위치, 피부 질환 여부 등)을 의료기기 사용
            전에 이해해야 합니다. <br />
            • 강도 높은 운동 시 땀이나 센서의 움직임으로 인해 센서가 느슨해져
            정확하지 않은 측정 결과가 나타날 수 있으며, 센서가 운동 중에 떨어진
            경우 적절한 부착 부위를 선택하여 새 센서를 사용해야 합니다. <br />
            • X-선, MRI 또는 CT 스캔과 같이 강한 자기 또는 전자기 방사선을
            포함하는 검사가 있는 경우, 부착한 센서를 제거하고 검사 후에 새
            센서를 부착해야 합니다. 이러한 절차가 센서의 성능에 미치는 영향은
            평가되지 않았습니다. <br />
            • 센서가 삽입된 부위가 불편하거나 피부 자극을 느낄 경우 즉시 사용을
            중단하고 전문가(의사)와 상담해야 합니다. <br />
            • 연속 혈당측정기로 측정된 혈당 농도만으로 의학적인 결정이나 해석을
            할 수 없으며, 저혈당이나 당뇨병 등의 증상이 의심될 때는 반드시
            전문가(의사)와 상담해야 합니다. <br />
            • 혈당 농도로만 체중 감소를 판단할 수 없으며, 종합적인 영양 정보 및
            식습관 개선이 필요합니다. 적절한 운동과 포화지방이 많은 음식 섭취를
            자제하는 것이 중요합니다. <br />
            • 의료기기 부작용 보고 관련 문의는
            한국의료기기안전정보원(080-080-4183)으로 할 수 있습니다. <br />•
            의료기기 허가(인증·신고) 상세 정보는 의료기기안심 책방에서 검색
            가능합니다: <a href="https://emedi.mfds.go.kr/">링크</a> <br />
          </div>
        )}
        <div
          className="faq-question-container-last"
          onClick={() => onClickHandler(13)}
          style={{
            // borderBottom: `1px solid ${index !== 12 ? '#E0E4E4' : '#FFFFFF'}`,
            borderRadius: `${
              index !== 13
                ? '0rem 0rem 0.625rem 0.625rem'
                : '0rem 0rem 0rem 0rem'
            }`,
          }}
        >
          <div className="faq-question strong">환불 정책</div>
          <img src={index === 13 ? chevronUp : chevronDown} alt="img" />
        </div>
        {index === 13 && (
          <div className="faq-answer-container-last">
            <p>
              <div className="strong">환불 정책(2024/6/14 이후 구매의 건)</div>
            </p>
            <ol type="1">
              <li>
                리브레 센서 비용의 정가는 104,500원입니다.
                <ol type="a">
                  <li>택배 출고 전 환불 신청 시 전액 환불됩니다.</li>
                  <li>
                    배송이 완료된 상품은 상품 수령일로부터 7일 이내, 개봉 전
                    상품에 한해 전액 환불이 가능합니다. 단, 반품 시에 발생하는
                    왕복 배송비는 고객님 부담입니다.
                    <ol type="i">
                      <li>전자상거래 등에서의 소비자보호에 관한 법률 제17조</li>
                    </ol>
                  </li>
                  <li>
                    센서를 수령하여 부착한 이후에는 의료기기로 사용된 것이기
                    때문에 환불이 불가합니다.
                  </li>
                  <li>
                    CGM 센서는 수령 후 1개월이 지나는 경우 환불이 불가합니다.
                  </li>
                  <li>
                    이벤트로 CGM을 무료로 받은 경우 환불이 되지 않으며 중간
                    멤버십 해지를 하는 경우 센서 비용을 지불해야 합니다.
                  </li>
                  <li>
                    상품 개봉 후 환불은 불가하며, 초기 상품 하자일 경우 새
                    상품으로 1:1 교체해드립니다.
                  </li>
                  <li>
                    아래에 해당한 경우 교환 및 환불이 불가합니다.
                    <ol type="i">
                      <li>
                        소비자의 고의, 과실로 인한 상품 파손 및 훼손된 경우
                      </li>
                      <li>상품 구매 후 개봉 및 내/외부 포장 파손 시</li>
                      <li>
                        상품의 구조, 기능을 개조 또는 상품을 변조하여 문제가
                        발생된 경우
                      </li>
                      <li>천재지변에 의해 발생한 피해</li>
                    </ol>
                  </li>
                  <li>
                    교환 및 반품 환불은 상품 도착 및 상품상태 점검 후
                    이루어집니다.
                    <ol type="i">
                      <li>
                        반품 상품 점검 시 교환/반품 불가 사유에 해당될 경우
                        반품/환불은 불가합니다.
                      </li>
                      <li>
                        구성품의 일부가 누락되거나 반품 입고가 되지 않았을 경우
                        환불이 지연될 수 있습니다.
                      </li>
                      <li>
                        지정된 택배사 외 타 업체 이용 시 반품/교환이 지연되거나
                        추가 비용이 발생될 수 있습니다.
                      </li>
                    </ol>
                  </li>
                  <li>환불 진행시 왕복 택배비 9,000원은 소비자 부담입니다.</li>
                </ol>
              </li>
              <li>
                1년 멤버십 중도해지 정책
                <ol type="a">
                  <li>
                    ‘104,500원 x (센서 사용 갯수) + 멤버십 중도 해지 수수료’가
                    부과됩니다.
                    <ol type="i">
                      <li>3개월 이전 해지시 중도 해지 수수료 70,000원</li>
                      <li>3개월 이후 해지시 중도 해지 수수료 0원</li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>

            <p>
              <div className="strong">환불 정책(2024/6/14 이전 구매의 건)</div>
            </p>
            <ol type="1">
              <li>
                <div>CGM 센서가 포함된 글루코핏 프로그램 환불 정책</div>
                <ol type="a">
                  <li>CGM 센서의 정가는 90,000원입니다.</li>
                  <li>택배 출고 전 환불 신청 시 전액 환불됩니다.</li>
                  <li>
                    배송이 완료된 상품은 상품 수령일로부터 7일 이내, 개봉 전
                    상품에 한해 전액 환불이 가능합니다. 단, 반품 시에 발생하는
                    왕복 배송비는 고객님 부담입니다.
                    <ol type="i">
                      <li>전자상거래 등에서의 소비자보호에 관한 법률 제17조</li>
                    </ol>
                  </li>
                  <li>
                    센서를 수령하여 부착한 이후에는 의료기기로 사용된 것이기
                    때문에 환불이 불가합니다.
                  </li>
                  <li>
                    CGM 센서는 수령 후 1개월이 지나는 경우 환불이 불가합니다.
                  </li>
                  <li>
                    이벤트로 CGM을 무료로 받은 경우 환불이 되지 않으며 중간
                    멤버십 해지를 하는 경우 센서 비용을 지불해야 합니다.
                  </li>
                  <li>
                    상품 개봉 후 환불은 불가하며, 초기 상품 하자일 경우 새
                    상품으로 1:1 교체해드립니다.
                  </li>
                  <li>
                    아래에 해당한 경우 교환 및 환불이 불가합니다.
                    <ol type="i">
                      <li>
                        소비자의 고의, 과실로 인한 상품 파손 및 훼손된 경우
                      </li>
                      <li>상품 구매 후 개봉 및 내/외부 포장 파손 시</li>
                      <li>
                        상품의 구조, 기능을 개조 또는 상품을 변조하여 문제가
                        발생된 경우
                      </li>
                      <li>천재지변에 의해 발생한 피해</li>
                    </ol>
                  </li>
                  <li>
                    교환 및 반품 환불은 상품 도착 및 상품상태 점검 후
                    이루어집니다.
                    <ol type="i">
                      <li>
                        반품 상품 점검 시 교환/반품 불가 사유에 해당될 경우
                        반품/환불은 불가합니다.
                      </li>
                      <li>
                        구성품의 일부가 누락되거나 반품 입고가 되지 않았을 경우
                        환불이 지연될 수 있습니다.
                      </li>
                      <li>
                        지정된 택배사 외 타 업체 이용 시 반품/교환이 지연되거나
                        추가 비용이 발생될 수 있습니다.
                      </li>
                    </ol>
                  </li>
                  <li>환불 진행시 왕복 택배비 9,000원은 소비자 부담입니다.</li>
                </ol>
              </li>
            </ol>

            <p>
              <div className="strong">
                멤버십 해지 정책(2024/6/14 이전 구매의 건)
              </div>
            </p>
            <ol type="1">
              <li>
                <div>1년 멤버십 중간 해지</div>
                <ol type="a">
                  <li>
                    결제일로부터 35일 이내
                    <ol type="i">
                      <li>
                        276,000원(1개월 사용료 정가)에서 기결제액을 차감한
                        금액이 부과됩니다.
                      </li>
                      <li>
                        사용하지 않은 CGM 센서가 있는 경우
                        <ol type="1">
                          <li>
                            276,000원(1개월 사용료 정가) - 기결제액 -
                            88,000원/사용하지 않은 CGM 센서 갯수 가 부과됩니다.
                          </li>
                        </ol>
                      </li>
                      <li>
                        1년 멤버십 중간 해지시 이벤트로 증정된 센서를 사용하신
                        경우 88,000원/개 결제가 필요합니다.
                      </li>
                    </ol>
                  </li>
                  <li>
                    결제일로부터 35일 이후
                    <ol type="i">
                      <li>
                        276,000원(1개월 사용료 정가) + 멤버십 30,000원/월 +
                        사용한 CGM 센서 88,000원/개
                      </li>
                      <li>
                        사용하지 않은 CGM 센서가 있는 경우 i. 에서 차감 후 부과
                      </li>
                      <li>
                        1년 멤버십 중간 해지시 이벤트로 증정된 센서를 사용하신
                        경우 88,000원/개 결제가 필요합니다.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <div>1개월 멤버십 중간 해지</div>
                <ol type="a">
                  <li>사용하지 않은 CGM 센서 수거 완료 후 88,000원/개 환불</li>
                </ol>
              </li>
            </ol>
          </div>
        )}
      </div>
      <div />
    </section>
  );
}

export default FaQ;
