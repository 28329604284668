import Report1 from 'assets/images/ver4.0/report/report1.webp';
import Report2 from 'assets/images/ver4.0/report/report2.webp';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';
import './Report.scss';
export default function Report() {
  return (
    <MainComponentLayout
      type="white"
      title={
        <h2>
          전문적인 지식을 쌓고 <br /> 정기적으로 나의 혈당 리포트를
          <br className="title-mobile" /> 받아보세요
        </h2>
      }
    >
      <section className="report-section">
        <div className="report-card">
          <img
            src={Report1}
            alt="100여 종에 달하는 전문적인 아티클과 영상이 준비되어 있어요"
            loading="lazy"
          />
          <p>
            100여 종에 달하는 전문적인 아티클과 <br />
            영상이 준비되어 있어요
          </p>
        </div>
        <div className="report-card">
          <img
            src={Report2}
            alt="내 혈당 데이터를 분석한 리포트를 정기적으로 전달드려요"
            loading="lazy"
          />
          <p>
            내 혈당 데이터를 분석한 리포트를 <br />
            정기적으로 전달드려요
          </p>
        </div>
      </section>
    </MainComponentLayout>
  );
}
