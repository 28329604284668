import { ReactComponent as CheckIcon } from 'assets/icons/ver4.0/special-plan/check.svg';
import { ReactComponent as SpecialPlanIcon } from 'assets/icons/ver4.0/special-plan/special-plan.svg';
import SpecialPlanMobileImage from 'assets/images/ver4.0/special-plan/special-plan-mobile.webp';
import SpecialPlanWebImage from 'assets/images/ver4.0/special-plan/special-plan-web.webp';

import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';

import './SpecialPlan.scss';

export default function SpecialPlan({ initCheckout }) {
  return (
    <MainComponentLayout type="gray">
      <section className="special-plan-section">
        <div className="special-plan">
          <div className="component-badge">Special Plan</div>
          <h2 className="special-plan-title">
            지금 글루코핏을 <br /> 경험해보세요
          </h2>
        </div>
        <div className="special-plan-card">
          <div className="special-plan-card-content">
            <div className="special-plan-card-content-top">
              <SpecialPlanIcon />
              <div className="special-plan-badge">Special</div>
              <h3>월 54,300원</h3>
              <h4>글루코핏 1년 스타터 패키지</h4>
            </div>
            <div className="special-plan-card-content-middle">
              <div className="special-plan-description">
                <CheckIcon />
                글루코핏 1년 멤버십
              </div>
              <div className="special-plan-description">
                <CheckIcon />
                연속혈당측정기 6개
              </div>
            </div>
            <div className="special-plan-card-content-bottom">
              <button onClick={initCheckout}>시작하기</button>
            </div>
          </div>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={SpecialPlanMobileImage}
            />
            <img
              src={SpecialPlanWebImage}
              alt="월 54000원 글루코핏 1년 스타터 패키지 사진"
              loading="lazy"
            />
          </picture>
        </div>
      </section>
    </MainComponentLayout>
  );
}
