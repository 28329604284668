import './MainComponentLayout.scss';
export default function MainComponentLayout({
  badgeText,
  title,
  type,
  children,
  style = {},
}) {
  return (
    <section
      className={`main-component-layout ${type}-component`}
      style={style}
    >
      {badgeText && <div className="component-badge">{badgeText}</div>}
      {title}
      <div className="component-content">{children}</div>
    </section>
  );
}
