import axios from 'axios';
import { TARGET_SERVER } from 'config';
import { useProduct } from 'hooks/product/useProduct';
import { useNavigate } from 'react-router-dom';

function usePayment() {
  const test = false;
  const PG_MID_TEST = process.env.REACT_APP_PG_MID_TEST;
  const PG_MID_REAL_INTEREST_FREE =
    process.env.REACT_APP_PG_MID_REAL_INTEREST_FREE;
  const PG_MID_REAL = process.env.REACT_APP_PG_MID_REAL;

  const navigate = useNavigate();
  const { PRODUCT_TYPE, findProductById } = useProduct();

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/[^\d]/g, '');
  };

  const formatAddress = (address, detailAddress) => {
    return `${address}${detailAddress ? ' ' + detailAddress : ''}`;
  };

  const getPgId = (interestFree, payMethod) => {
    if (test) {
      return PG_MID_TEST;
    }
    // 2024.07.06 현재 간편결제는 PG_MID_REAL_INTEREST_FREE로만 가능함
    if (payMethod === 'naverpay' || payMethod === 'kakaopay') {
      return PG_MID_REAL_INTEREST_FREE;
    }
    return interestFree ? PG_MID_REAL_INTEREST_FREE : PG_MID_REAL;
  };

  // 일반결제 관련 (completeOrder, requestPay, createOrder)
  const completeOrder = async ({
    productId,
    impUid,
    merchantUid,
    redirectPath,
  }) => {
    const product = findProductById(productId);
    if (!product) {
      alert('상품 정보를 찾을 수 없습니다.');
      throw new Error('상품 정보를 찾을 수 없습니다.');
    }
    if (!merchantUid) {
      alert('merchantUid가 없습니다.');
      throw new Error('merchantUid가 없습니다.');
    }
    const isFreeOrder = impUid === null;
    try {
      const response = await axios.post(TARGET_SERVER, {
        query: isFreeOrder
          ? `
            mutation completeOrderFreeUser($input: complete_order_free_user_input!) {
              completeOrderFreeUser(complete_order_free_user_input: $input)
            }
          `
          : `
            mutation completeOrder($input: complete_order_input!) {
              completeOrder(complete_order_input: $input)
            }
          `,
        variables: {
          input: isFreeOrder
            ? {
                merchant_uid: merchantUid,
              }
            : {
                imp_uid: impUid,
                merchant_uid: merchantUid,
              },
        },
      });
      if (
        response.data.data[
          isFreeOrder ? 'completeOrderFreeUser' : 'completeOrder'
        ] !== 'success'
      ) {
        throw new Error();
      }
      if (
        product.type === PRODUCT_TYPE.MONTH_MEMBERSHIP ||
        product.type === PRODUCT_TYPE.YEAR_MEMBERSHIP
      ) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: merchantUid,
            currency: 'KRW',
            value: product.price - product.discount,
            items: [
              {
                item_id: product.type === '1개월' ? 1 : 2,
                item_name:
                  product.type === '1개월' ? '1개월_멤버십' : '1년_멤버십',
                item_variant: product.sensorCount + product.eventSensorCount,
                quantity: 1,
                price: product.price - product.discount,
              },
            ],
          },
        });
      }
      navigate(redirectPath);
    } catch (error) {
      alert('주문 완료에 실패했습니다.');
      throw new Error(error);
    }
  };

  const requestPay = async ({
    order,
    merchantUid,
    productId,
    productPrice,
    inviteCode = null,
    mRedirectUrl,
    interestFree = true,
  }) => {
    const product = findProductById(productId);
    if (!product) {
      alert('상품 정보를 찾을 수 없습니다.');
      throw new Error('상품 정보를 찾을 수 없습니다.');
    }
    if (!mRedirectUrl) {
      alert('mRedirectUrl이 필요합니다.');
      throw new Error('mRedirectUrl이 필요합니다.');
    }
    if (!merchantUid) {
      alert('merchantUid가 필요합니다.');
      throw new Error('merchantUid가 필요합니다.');
    }
    if (productPrice <= 0) {
      return null; // completeOrderFreeUser로 유도
    }
    return new Promise((resolve, reject) => {
      window.IMP.request_pay(
        {
          pg: getPgId(interestFree, order.payMethod.value),
          pay_method: order.payMethod.value,
          m_redirect_url: mRedirectUrl,
          merchant_uid: merchantUid,
          name:
            product.type === PRODUCT_TYPE.YEAR_MEMBERSHIP
              ? `글루코핏_${inviteCode ? '친구초대' : ''}1년멤버십${
                  product.sensorCount
                }_일반결제_365`
              : product.type === PRODUCT_TYPE.MONTH_MEMBERSHIP
              ? `글루코핏_${inviteCode ? '친구초대' : ''}1개월멤버십${
                  product.sensorCount
                }_일반결제_30`
              : product.name,
          amount: productPrice,
          buyer_name: order.buyerName.value,
          buyer_tel: order.buyerPhoneNumber.value,
          buyer_addr: formatAddress(
            order.address.value,
            order.detailAddress.value,
          ),
          buyer_postcode: order.postcode.value,
        },
        (response) => {
          if (Object.keys(response).includes('error_code')) {
            alert(response.error_msg);
            reject(response.error_msg);
          } else {
            resolve(response.imp_uid);
          }
        },
      );
    });
  };

  const createOrder = async ({
    order,
    productId,
    productCount,
    totalPrice,
    couponNumber = null,
    inviteCode = null,
  }) => {
    if (totalPrice < 0) {
      alert('결제 금액이 0원 이하입니다.');
      throw new Error('결제 금액이 0원 이하입니다.');
    }
    if (productCount < 1) {
      alert('상품 개수가 1개 이하입니다.');
      throw new Error('상품 개수가 1개 이하입니다.');
    }

    try {
      const response = await axios.post(TARGET_SERVER, {
        query: `
            mutation createOrder($input: create_order_input!) {
              createOrder(create_order_input: $input) {
                merchant_uid
                customer_uid
              }
            }
          `,
        variables: {
          input: {
            payer_name: order.buyerName.value,
            payer_phone_number: formatPhoneNumber(order.buyerPhoneNumber.value),
            recipient_phone_number: formatPhoneNumber(
              order.userPhoneNumber.value,
            ),
            recipient_name: order.userName.value,
            address: formatAddress(
              order.address.value,
              order.detailAddress.value,
            ),
            postal_code: order.postcode.value,
            amount: totalPrice,
            invite_code: inviteCode,
            coupon_number: couponNumber,
            product_id: productId,
            product_count: productCount,
          },
        },
      });
      if (!response?.data?.data?.createOrder?.merchant_uid) {
        throw new Error();
      }
      return response.data.data.createOrder.merchant_uid;
    } catch (error) {
      console.log(error);
      alert('주문 생성에 실패했습니다.');
      throw new Error(error);
    }
  };

  return { createOrder, requestPay, completeOrder };
}

export default usePayment;
