import QuestionMobileImage from 'assets/images/ver4.0/question/question-mobile.webp';
import QuestionWebImage from 'assets/images/ver4.0/question/question-web.webp';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';
import './Question.scss';

export default function Question() {
  return (
    <MainComponentLayout
      type="gray"
      title={
        <>
          <h3 id="question-section-h3">궁금한것이 있다면? 걱정마세요</h3>
          <h2>
            궁금한 내용은
            <br />
            <span className="badge-highlight">글루코핏 의사 코치님께</span>
            <br /> 언제든지 물어봐요
          </h2>
        </>
      }
      style={{
        paddingBottom: 0,
      }}
    >
      <picture className="question-section">
        <source media="(max-width: 768px)" srcSet={QuestionMobileImage} />
        <img
          src={QuestionWebImage}
          alt="글루코핏 코치와 대화 내용 이미지"
          loading="lazy"
        />
      </picture>
    </MainComponentLayout>
  );
}
