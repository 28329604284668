import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function CertificationComponent() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const openCertificationWindow = (
    merchantUid,
    callbackUrl,
    name,
    phoneNumber,
  ) => {
    window.IMP.certification(
      {
        pg: process.env.REACT_APP_CERTIFICATION_PG_MID,
        merchant_uid: merchantUid,
        m_redirect_url: `${
          process.env.REACT_APP_ENV === 'local'
            ? 'http://localhost:3000'
            : 'https://glucofit.co.kr'
        }/ciauth?callback_url=${callbackUrl}&name=${name}&phone_number=${phoneNumber}`,
        popup: false,
      },
      // when web callback
      function (rsp) {
        if (rsp.success) {
          navigate(
            `${callbackUrl}?imp_uid=${rsp.imp_uid}&merchant_uid=${rsp.merchant_uid}&name=${name}&phone_number=${phoneNumber}&success=${rsp.success}`,
          );
        } else {
          navigate(
            `${callbackUrl}?imp_uid=${rsp.imp_uid}&merchant_uid=${rsp.merchant_uid}&name=${name}&phone_number=${phoneNumber}&success=${rsp.success}&message=${rsp.error_msg}`,
          );
        }
      },
    );
  };

  useEffect(() => {
    const callbackUrl = searchParams.get('callback_url') || '/';
    const impUid = searchParams.get('imp_uid');
    const merchantUid = searchParams.get('merchant_uid');
    const isSuccess = searchParams.get('success');
    const name = searchParams.get('name');
    const phoneNumber = searchParams.get('phone_number');

    // open certification window
    if (impUid === null || isSuccess === null) {
      openCertificationWindow(merchantUid, callbackUrl, name, phoneNumber);
      return;
    }

    // when mobile redirect
    if (isSuccess === 'true') {
      navigate(
        `${callbackUrl}?imp_uid=${impUid}&merchant_uid=${merchantUid}&name=${name}&phone_number=${phoneNumber}&success=${isSuccess}`,
      );
    } else {
      navigate(
        `${callbackUrl}?imp_uid=${impUid}&merchant_uid=${merchantUid}&name=${name}&phone_number=${phoneNumber}&success=${isSuccess}&message=인증에 실패했습니다.`,
      );
    }
  }, [searchParams]);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
}
