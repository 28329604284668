import Footer from 'components/Footer/Footer';
import Nav from 'components/Nav/Nav';
import useOrder from 'hooks/order/useOrder';
import usePayment from 'hooks/payment/usePayment';
import { useProduct } from 'hooks/product/useProduct';
import MyDreamMembershipBenefit from 'pages/MyDream/Components/MyDreamMembershipBenefit';
import MyDreamSideBar from 'pages/MyDream/Components/MyDreamSideBar';
import OrderForm from 'pages/Payment/Components/OrderForm';
import PaymentMethodSelector from 'pages/Payment/Components/PaymentMethodSelector';
import Terms from 'pages/Payment/Components/Terms';
import { useLayoutEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function MyDreamPayment() {
  const orderFormRef = useRef();
  const [searchParams] = useSearchParams();
  const {
    order,
    setOrder,
    handleFindAddressClick,
    handleOrder,
    validateOrder,
  } = useOrder();
  const {
    PRODUCT_TYPE,
    selectedProduct,
    setSelectedProductById,
    setSelectedProductByType,
    findProductById,
  } = useProduct();
  const { createOrder, requestPay, completeOrder } = usePayment();

  const handlePayment = async () => {
    const test = false;
    const mRedirectUrl = test
      ? 'http://192.168.35.5:3000/redirect'
      : 'https://glucofit.co.kr/redirect';
    if (!validateOrder(['terms1'])) {
      return alert('구매자 정보를 입력해주세요.');
    }
    const totalPrice =
      selectedProduct.price - selectedProduct.discount > 0
        ? selectedProduct.price - selectedProduct.discount
        : 0;
    const merchantUid = await createOrder({
      order: order,
      productId: selectedProduct.id,
      productCount: 1,
      totalPrice: totalPrice,
    });
    const impUid = await requestPay({
      order: order,
      merchantUid: merchantUid,
      productId: selectedProduct.id,
      productPrice: totalPrice,
      mRedirectUrl: mRedirectUrl,
    });
    completeOrder({
      productId: selectedProduct.id,
      impUid: impUid,
      merchantUid: merchantUid,
      redirectPath: '/successpay',
    });
  };

  useLayoutEffect(() => {
    const productId = searchParams.get('product_id');
    if (productId) {
      const product = findProductById(productId);
      if (product?.type === PRODUCT_TYPE.UNUZ) {
        setSelectedProductById(productId);
        return;
      }
    }
    setSelectedProductByType(PRODUCT_TYPE.UNUZ);
  }, []);

  if (selectedProduct === null) return null;
  return (
    <div>
      <div className="nav-payment">
        <Nav />
      </div>
      <div className="membership-payment-body">
        <div className="payment-left-container">
          <div className="membership-payment-title strong">주문/결제</div>
          <div className="membership-payment-container">
            <div className="membership-payment-subtitle">주문상품 정보</div>
            <MyDreamMembershipBenefit
              sensorCount={selectedProduct.sensorCount}
            />
          </div>
          <div className="membership-payment-container">
            <div className="membership-payment-subtitle" ref={orderFormRef}>
              구매자 정보
            </div>
            <OrderForm
              order={order}
              setOrder={setOrder}
              handleFindAddressClick={handleFindAddressClick}
              handleOrder={handleOrder}
            />
          </div>
          <div className="membership-payment-container">
            <div className="membership-payment-subtitle">결제 수단</div>
            <PaymentMethodSelector order={order} handleOrder={handleOrder} />
          </div>
        </div>
        <div className="payment-right-container">
          <div className="payment-info-title">결제 금액</div>
          <MyDreamSideBar
            price={selectedProduct.price}
            discount={selectedProduct.discount}
            isButtonDisabled={!order.terms2.value}
            handleButtonClicked={handlePayment}
          >
            <Terms order={order} handleOrder={handleOrder} onlyRefundTerms />
          </MyDreamSideBar>
        </div>
      </div>
      <Footer />
    </div>
  );
}
