import { Alert, Box, Button, TextField } from '@mui/material';
import axios from 'axios';
import Footer from 'components/Footer/Footer';
import Nav from 'components/Nav/Nav';
import { TARGET_SERVER } from 'config';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function CancelOrder() {
  const [input, setInput] = useState({ name: '', phoneNumber: '' });
  const [isVerified, setIsVerified] = useState(false);
  const [cancelableOrders, setCancelableOrders] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const CALLBACK_PATH = '/payment/cancel-order';

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSearch = async () => {
    const foundOrders = await findOrders(input.name, input.phoneNumber);
    if (!foundOrders || foundOrders.length === 0) {
      setCancelableOrders(foundOrders);
      return;
    }

    // 본인인증 페이지로 이동
    const merchantUid = new Date().toISOString() + '_' + input.phoneNumber;
    navigate(
      `/ciauth?merchant_uid=${merchantUid}&callback_url=${CALLBACK_PATH}&name=${input.name}&phone_number=${input.phoneNumber}`,
    );
  };

  const cancelOrder = async (orderId) => {
    if (!window.confirm('해당 주문을 취소하시겠습니까?')) {
      return;
    }
    const response = await axios.post(TARGET_SERVER, {
      query: `
          mutation($order_id: Int!) {
            cancelOrder(order_id: $order_id)
          }
      `,
      variables: {
        order_id: Number(orderId),
      },
    });
    if (response.data.errors?.length > 0) {
      alert('주문 취소가 불가능합니다. 고객센터로 문의해주세요.');
    } else {
      alert('주문이 취소되었습니다.');
      const foundOrders = await findOrders(input.name, input.phoneNumber);
      setCancelableOrders(foundOrders);
    }
  };

  const findOrders = async (name, phoneNumber) => {
    const response = await axios.post(TARGET_SERVER, {
      query: `
          query($name: String!, $phone_number: String!) {
            getCancelableOrderList(name: $name, phone_number: $phone_number) {
              is_cancelable
              order {
                id
                status
                payer_name
                payer_phone_number
                product_count
                product {
                  name
                }
                payment {
                  amount
                }
                created_at
              }
            }
          }
      `,
      variables: {
        name,
        phone_number: phoneNumber,
      },
    });
    return response.data.data.getCancelableOrderList;
  };

  // 본인인증한 정보 조회
  const getCertification = async (impUid, merchantUid, name, phoneNumber) => {
    const response = await axios.post(TARGET_SERVER, {
      query: `
          query($input: certification_input!) {
            getCertification(input: $input) {
              name
              phone_number
            }
          }
      `,
      variables: {
        input: {
          imp_uid: impUid,
          merchant_uid: merchantUid,
          name,
          phone_number: phoneNumber,
        },
      },
    });
    if (response.data.errors?.length > 0) {
      throw new Error(response.data.errors[0].message);
    }
    const certifiedData = response.data.data.getCertification;
    return {
      name: certifiedData.name,
      phoneNumber: certifiedData.phone_number,
    };
  };

  useEffect(() => {
    const imp_uid = searchParams.get('imp_uid');
    const merchant_uid = searchParams.get('merchant_uid');
    const success = searchParams.get('success');
    const message = searchParams.get('message');
    const inputName = searchParams.get('name');
    const inputPhoneNumber = searchParams.get('phone_number');

    // 본인인증 전
    if (imp_uid === null || merchant_uid === null || success === null) {
      return;
    }

    // 본인인증 후
    const process = async (impUid, merchantUid) => {
      try {
        const { name, phoneNumber } = await getCertification(
          impUid,
          merchantUid,
          inputName,
          inputPhoneNumber,
        );
        const foundOrders = await findOrders(name, phoneNumber);
        setIsVerified(true);
        setCancelableOrders(foundOrders);
      } catch (error) {
        alert(error.message);
      }
    };

    if (imp_uid && merchant_uid && success === 'true') {
      process(imp_uid, merchant_uid);
    } else {
      alert(message);
    }
  }, [searchParams]);

  return (
    <>
      <Nav />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: 'calc(100vh - 15rem)',
          padding: '6rem 0',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Box
          sx={{
            width: '65%',
            maxWidth: '35rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Box sx={{ width: '100%', fontSize: '1.5rem' }}>주문자 정보</Box>
          <TextField
            fullWidth
            color="success"
            label="이름"
            placeholder="홍길동"
            name="name"
            sx={{ backgroundColor: 'white' }}
            value={input.name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            color="success"
            label="전화번호"
            placeholder="'-' 빼고 입력 ex) 01012345678"
            name="phoneNumber"
            sx={{ backgroundColor: 'white' }}
            value={input.phoneNumber}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            fullWidth
            color="success"
            onClick={handleSearch}
          >
            검색
          </Button>
        </Box>
        <Box
          sx={{
            width: '65%',
            maxWidth: '35rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {cancelableOrders !== null &&
            (cancelableOrders.length === 0 ? (
              <Alert sx={{ marginTop: '2rem' }} severity="warning">
                {searchParams.get('name')}님의 취소 가능한 주문 내역이 없습니다.
                추가적인 문의사항은{' '}
                <a
                  href="https://glucofit.channel.io/workflows/685226"
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  고객센터
                </a>
                로 문의해주세요.
              </Alert>
            ) : (
              isVerified && (
                <>
                  <Box
                    sx={{
                      width: '100%',
                      fontSize: '1.5rem',
                      marginTop: '5rem',
                    }}
                  >
                    <Box color="success.main" display="inline">
                      {searchParams.get('name')}
                    </Box>
                    님의 취소 가능한 주문내역
                  </Box>
                  {cancelableOrders.map(({ is_cancelable, order }) => (
                    <Box
                      key={order.id}
                      sx={{
                        width: '100%',
                        border: '1px solid #DCDCDC',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        backgroundColor: 'white',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: '0.8rem',
                          color: '#708090',
                          marginBottom: '0.6rem',
                        }}
                      >
                        {new Date(order.created_at).toLocaleString()}
                      </Box>
                      <Box sx={{ fontSize: '1.3rem', marginBottom: '0.2rem' }}>
                        {order.product?.name || '글루코핏 상품'}
                      </Box>
                      <Box sx={{ fontSize: '1rem', color: '#808080' }}>
                        {Number(order.payment.amount).toLocaleString()}원,{' '}
                        {order.product_count || 1}개
                      </Box>
                      <Box sx={{ marginTop: '1.2rem' }}>
                        {order.status === 'CANCEL' && (
                          <Button variant="contained" fullWidth disabled>
                            이미 취소된 주문입니다.
                          </Button>
                        )}
                        {order.status !== 'CANCEL' && is_cancelable && (
                          <Button
                            color="error"
                            variant="outlined"
                            fullWidth
                            onClick={() => cancelOrder(order.id)}
                          >
                            주문취소
                          </Button>
                        )}
                        {order.status !== 'CANCEL' && !is_cancelable && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '0.9rem',
                              border: '1px solid #cccccc',
                              color: '#808080',
                              height: '2.25rem',
                              borderRadius: '0.25rem',
                            }}
                          >
                            해당 주문은 &nbsp;
                            <a
                              href="https://glucofit.channel.io/workflows/685226"
                              style={{
                                textDecoration: 'underline',
                              }}
                            >
                              고객센터
                            </a>
                            로 문의바랍니다.
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}
                </>
              )
            ))}
        </Box>
      </Box>
      <Footer />
    </>
  );
}
