import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoMobile from '../../assets/icons/ic-logo-mobile.svg';
import Logo from '../../assets/icons/ic-main-logo-black.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/ic_close.svg';
import { ReactComponent as NavBarIcon } from '../../assets/icons/ic_menu.svg';

import './Nav.scss';

function Nav({ initCheckout }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="main-page">
      <div className="nav-left">
        <div className="logo">
          <img
            src={Logo}
            alt="글루코핏"
            className="logo-image"
            onClick={handleLogoClick}
          />
        </div>
      </div>
      <div className="nav-right">
        <ul className="menu">
          <li>
            <a
              href="https://bit.ly/3UZXaxr"
              target="_blank"
              rel="noopener noreferrer"
            >
              제휴문의
            </a>
          </li>
          <li>
            <a
              href="https://blog.glucofit.co.kr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              건강 매거진
            </a>
          </li>
          <li>
            <a
              href="https://glucofit.co.kr/review"
              target="_blank"
              rel="noopener noreferrer"
            >
              글루코핏 후기
            </a>
          </li>
          <li>
            <a
              href="https://glucofit.channel.io/workflows/685226"
              target="_blank"
              rel="noopener noreferrer"
            >
              고객센터
            </a>
          </li>
        </ul>
        <button className="nav-start-button strong" onClick={initCheckout}>
          시작하기
        </button>
      </div>
      <div className="icon" onClick={toggleMenu}>
        {isMenuOpen ? <CloseIcon /> : <NavBarIcon />}
      </div>
      <img
        src={LogoMobile}
        alt="logo"
        className="mobile-logo"
        onClick={handleLogoClick}
      />
      {isMenuOpen && (
        <div className="menu-opened">
          <ul className="menu-items strong">
            <li>
              <a href="https://blog.glucofit.co.kr/">건강 매거진</a>
            </li>
            <li>
              <a href="https://glucofit.co.kr/review">글루코핏 후기</a>
            </li>
            <li>
              <a href="https://glucofit.channel.io/workflows/685226">
                고객센터
              </a>
            </li>
            <li>
              <a href="https://bit.ly/3UZXaxr">제휴문의</a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Nav;
