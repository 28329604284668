import CardMobile1 from 'assets/images/ver4.0/worry/card1-mobile.webp';
import CardWeb1 from 'assets/images/ver4.0/worry/card1-web.webp';
import CardMobile2 from 'assets/images/ver4.0/worry/card2-mobile.webp';
import CardWeb2 from 'assets/images/ver4.0/worry/card2-web.webp';
import CardMobile3 from 'assets/images/ver4.0/worry/card3-mobile.webp';
import CardWeb3 from 'assets/images/ver4.0/worry/card3-web.webp';
import CardMobile4 from 'assets/images/ver4.0/worry/card4-mobile.webp';
import CardWeb4 from 'assets/images/ver4.0/worry/card4-web.webp';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';
import './Worry.scss';

export default function Worry() {
  return (
    <MainComponentLayout
      badgeText="혹시 이런 고민이 있으신가요?"
      title={
        <h2>
          혈당 관리를 시작하고 싶은데,
          <br />
          방법을 모르겠어요
        </h2>
      }
      type="white"
    >
      <div className="worry-card-container">
        <picture>
          <source media="(max-width: 1100px)" srcSet={CardMobile1} />
          <img
            src={CardWeb1}
            alt="건강검진에서 혈당이 높다는 이야기를 들었어요"
            loading="lazy"
          />
        </picture>
        <picture>
          <source media="(max-width: 1100px)" srcSet={CardMobile2} />
          <img
            src={CardWeb2}
            alt="혈당 스파이크로부터 혈관을 지키고 싶어요"
            loading="lazy"
          />
        </picture>
        <picture>
          <source media="(max-width: 1100px)" srcSet={CardMobile3} />
          <img
            src={CardWeb3}
            alt="부모님 가족력에 당 문제가 있어요"
            loading="lazy"
          />
        </picture>
        <picture>
          <source media="(max-width: 1100px)" srcSet={CardMobile4} />
          <img
            src={CardWeb4}
            alt="대사에 문제가 있는지 이상하게 안빠져요"
            loading="lazy"
          />
        </picture>
      </div>
    </MainComponentLayout>
  );
}
