import { ReactComponent as Logo1 } from 'assets/icons/ver4.0/introduction/carousel-logo1.svg';
import { ReactComponent as Logo2 } from 'assets/icons/ver4.0/introduction/carousel-logo2.svg';
import { ReactComponent as Logo3 } from 'assets/icons/ver4.0/introduction/carousel-logo3.svg';
import { ReactComponent as Logo4 } from 'assets/icons/ver4.0/introduction/carousel-logo4.svg';
import { ReactComponent as Logo5 } from 'assets/icons/ver4.0/introduction/carousel-logo5.svg';
import { ReactComponent as Logo6 } from 'assets/icons/ver4.0/introduction/carousel-logo6.svg';
import IntroVideo from 'assets/videos/introduction/introduction-web.mp4';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';
import './Introduction.scss';

export default function Introduction() {
  return (
    <MainComponentLayout type="white">
      <section className="introduction-section">
        <video muted autoPlay loop playsInline controls>
          <source src={IntroVideo} type="video/mp4" />
        </video>
        <p>유수의 언론과 대기업에서 주목한 글루코핏</p>
        <section className="carousel-section">
          <div className="carousel-dimmed" />
          <div className="carousel-content">
            <Logo1 width={150} />
            <Logo2 width={175} />
            <Logo3 width={152} />
            <Logo4 width={140} />
            <Logo5 width={250} />
            <Logo6 width={300} style={{ marginRight: 40 }} />
          </div>
          <div className="carousel-content">
            <Logo1 width={150} />
            <Logo2 width={175} />
            <Logo3 width={152} />
            <Logo4 width={140} />
            <Logo5 width={250} />
            <Logo6 width={300} />
          </div>
        </section>
      </section>
    </MainComponentLayout>
  );
}
