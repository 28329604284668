import { ReactComponent as RightArrowIcon } from 'assets/icons/ver4.0/banner/button-right-arrow.svg';
import Card1Image from 'assets/images/ver4.0/article/card1.webp';
import Card2Image from 'assets/images/ver4.0/article/card2.webp';
import Card3Image from 'assets/images/ver4.0/article/card3.webp';
import { useMobile } from 'hooks/common/useMobile';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Article.scss';

export default function Article() {
  const { isMobile } = useMobile(1100);
  const swiperSlideStyle = isMobile
    ? {
        display: 'flex',
        justifyContent: 'center',
        width: '17rem',
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        width: '22.8rem',
      };

  return (
    <MainComponentLayout
      type="white"
      badgeText="Glucofit Articles"
      title={
        <h2>
          글루코핏 의사 코치님들의 <br /> 전문적인 아티클을 확인해보세요
        </h2>
      }
    >
      <section className="article-section">
        <Swiper
          allowSlideNext={isMobile}
          allowSlidePrev={isMobile}
          initialSlide={1}
          slidesPerView="auto"
          centeredSlides={true}
        >
          <SwiperSlide style={swiperSlideStyle}>
            <div className="article-card">
              <a
                href="https://blog.glucofit.co.kr/untitled-2/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Card2Image}
                  alt="건강한 혈당관리를 위한 단백질 섭취 가이드"
                />
                <h4>건강 아티클</h4>
                <h3>건강한 혈당관리를 위한 단백질 섭취 가이드</h3>
                <p>
                  단백질의 주요 혈당 건강상의 이점, 하루 및 각 식사에서 목표로
                  해야 할 단백질 섭취량, 동물성과 식물성 단백질의 차이점, 그리고
                  충분한 단백질을 섭취하는 방법에 대해 알려드리겠습니다.
                </p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide style={swiperSlideStyle}>
            <div className="article-card">
              <a
                href="https://blog.glucofit.co.kr/hyeoldang-ceugjeongeun-dangnyobyeong-hwanjadeulegeman-pilyohan-geos-aningayo/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Card1Image}
                  alt="혈당 측정은 당뇨인에게만 필요한 것 아닌가요?"
                />
                <h4>건강 아티클</h4>
                <h3>혈당 측정은 당뇨인에게만 필요한 것 아닌가요?</h3>
                <p>
                  당뇨인이 아니더라도 지속적으로 혈당을 측정하고 관리하는 것이
                  왜 중요한지, 혈당관리가 되지 않았을 때 어떤 문제가 생길 수
                  있는지 알려드리도록 할게요.
                </p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide style={swiperSlideStyle}>
            <div className="article-card">
              <a
                href="https://blog.glucofit.co.kr/daesajeunghugun/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Card3Image}
                  alt="대사증후군의 정의, 원인 그리고 예방을 위한 필수 수칙 6가지"
                />
                <h4>건강 아티클</h4>
                <h3>
                  대사증후군의 정의, 원인 그리고 예방을 위한 필수 수칙 6가지
                </h3>
                <p>
                  대사 증후군의 원인, 주의해야 할 징후와 증상, 그리고 이 상태의
                  발병 위험을 낮추고 대사 건강을 개선하는 데 도움이 될 수 있는
                  생활 방식 변화를 다루겠습니다.
                </p>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
        <a
          href="https://blog.glucofit.co.kr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="article-button">
            아티클 더 보러가기 <RightArrowIcon />
          </button>
        </a>
      </section>
    </MainComponentLayout>
  );
}
