import Card1Video from 'assets/videos/benefit/card1.mp4';
import Card2Video from 'assets/videos/benefit/card2.mp4';
import Card3Video from 'assets/videos/benefit/card3.mp4';
import Card4Video from 'assets/videos/benefit/card4.mp4';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';
import './Benefit.scss';

export default function Benefit() {
  return (
    <MainComponentLayout
      badgeText="Glucofit Benefits"
      title={
        <h2>
          글루코핏은 <span className="badge-highlight">어떻게</span>
          <br /> 혈당 스파이크 관리를 <br className="title-mobile" />
          도와주나요?
        </h2>
      }
    >
      <section className="benefit-section">
        <div className="benefit-card-container">
          <div className="benefit-card-type1">
            <video muted autoPlay loop playsInline>
              <source src={Card1Video} type="video/mp4" />
            </video>
            <div>
              <h3 className="benefit-text-h2 benefit-text-color2">
                실시간 혈당 측정기로 통증 없이! 채혈 없이!
              </h3>
              <p className="benefit-text-h3 benefit-text-color1">
                혈당이 오르고 내리는 것을 블루투스 방식으로 '실시간'으로 확인할
                수 있어요.
              </p>
            </div>
          </div>
          <div className="benefit-card-type1">
            <video muted autoPlay loop playsInline>
              <source src={Card2Video} type="video/mp4" />
            </video>
            <div>
              <h3 className="benefit-text-h2 benefit-text-color3">
                ‘찰칵’ 카메라로 간단하게 음식을 기록해요
              </h3>
              <p className="benefit-text-h3 benefit-text-color1">
                가볍게 사진만 찍으면 똑똑한 AI가 음식을 알아서 분석하고
                기록해드려요.
              </p>
            </div>
          </div>
        </div>
        <div className="benefit-card-container">
          <div className="benefit-card-type2">
            <div className="benefit-card-description">
              <h4 className="benefit-text-h3 benefit-text-color4">
                혈당 스파이크가 발생하면 늦어요!
              </h4>
              <h3 className="benefit-text-h1">
                오르기 전에 <br />
                <span className="benefit-text-color3">미리 알려드릴게요!</span>
              </h3>
              <p className="benefit-text-h3 benefit-text-color1">
                혈당 스파이크가 생기는 순간 혈관이 다치고&nbsp;
                <br className="text-web" />
                혈당 건강과 다이어트 모두에 좋지 않아요. 특허 6건이 출원된
                <br /> 글루코핏 만의 기술로 오르기 전에 미리 알려 드릴게요!
              </p>
              <div className="benefit-badge"># Only Glucofit</div>
            </div>
            <video muted autoPlay loop playsInline>
              <source src={Card3Video} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="benefit-card-container">
          <div className="benefit-card-type2" id="last-card">
            <video muted autoPlay loop playsInline>
              <source src={Card4Video} type="video/mp4" />
            </video>
            <div className="benefit-card-description">
              <h4 className="benefit-text-h3 benefit-text-color4">
                스파이크가 생겼는데 어쩌지?
              </h4>
              <h3 className="benefit-text-h1">
                혈당 스파이크 전후에 <br />
                무엇을 해야 하는지 <br />
                <span className="benefit-text-color3">즉시 알려드릴게요</span>
              </h3>
              <p className="benefit-text-h3 benefit-text-color1">
                혈당 스파이크가 생겼더라도 조절해 나가면 괜찮아요. <br /> 수백
                가지의 혈당 조절 방법 중 ‘나에게’ 맞는 맞춤형
                <br /> 혈당관리 피드백을 ‘즉시’ 받아보세요!
              </p>
              <div className="benefit-badge"># Only Glucofit</div>
            </div>
          </div>
        </div>
      </section>
    </MainComponentLayout>
  );
}
