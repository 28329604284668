import { ReactComponent as RightArrowIcon } from 'assets/icons/ver4.0/banner/button-right-arrow.svg';
import { useMobile } from 'hooks/common/useMobile';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Coach.scss';

import Coach1 from 'assets/images/ver4.0/coach/coach1.webp';
import Coach2 from 'assets/images/ver4.0/coach/coach2.webp';
import Coach3 from 'assets/images/ver4.0/coach/coach3.webp';

export default function Coach() {
  const { isMobile } = useMobile(1100);
  const swiperSlideStyle = isMobile
    ? {
        display: 'flex',
        justifyContent: 'center',
        width: '20rem',
        height: '40.5rem',
        paddingTop: '0.4rem',
        overflow: 'visible',
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        width: '22.8rem',
        height: '47rem',
        paddingTop: '0.4rem',
        overflow: 'visible',
      };
  return (
    <MainComponentLayout
      badgeText="내 손 안의 병원, 글루코핏"
      title={
        <h2>
          가장 전문적인 대사관리를 위한
          <br />
          글루코핏 의사 코치진
        </h2>
      }
      type="gray"
    >
      <Swiper
        allowSlideNext={isMobile}
        allowSlidePrev={isMobile}
        initialSlide={isMobile ? 0 : 1}
        slidesPerView="auto"
        centeredSlides={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide style={swiperSlideStyle}>
          <div className="coach-profile">
            <img src={Coach1} alt="의사 양혁용" />
            <div className="coach-profile-info">
              <p>글루코핏 대표</p>
              <h3>의사 양혁용</h3>
            </div>
            <div className="coach-history">
              6년간 15kg의 요요가 반복되는 것을 직접 경험하고 극복했습니다. 또한
              다이어트와 대사에 관한 논문을 읽고 스스로에게 실험하며 1600명이
              넘는 분들의 다이어트를 도왔습니다.
              <br />
              <br />
              제가 직접 경험한 지속가능하고 건강한 혈당 다이어트를 더 많은
              분들이 경험할 수 있으면 좋겠습니다.
            </div>
            <a
              href="https://blog.glucofit.co.kr/geulrukopis-cangeob-seutori"
              target="_blank"
              rel="noreferrer"
            >
              <div className="coach-link">
                더 보기
                <RightArrowIcon />
              </div>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide style={swiperSlideStyle}>
          <div className="coach-profile">
            <img src={Coach2} alt="가정의학과 전문의 이진복 원장" />
            <div className="coach-profile-info">
              <p>닥터리 가정의학과</p>
              <h3>가정의학과 전문의 이진복 원장</h3>
            </div>
            <ul className="coach-history">
              <li>· 서울대 보건대학원 수료</li>
              <li>· 대한가정의학회 정회원</li>
              <li>· 전) 고려대 의대 외래교수</li>
              <li>· 전) 카톨릭 의대 외래교수</li>
              <li>· 현) 인제대 의대 외래교수</li>
              <li>· 현) 차의과대학 외래교수</li>
              <li>· 13만 구독자 닥터리TV 운영</li>
            </ul>
          </div>
        </SwiperSlide>
        <SwiperSlide style={swiperSlideStyle}>
          <div className="coach-profile">
            <img src={Coach3} alt="가정의학과 전문의 민혜연 원장" />
            <div className="coach-profile-info">
              <p>청담 에스민 의원</p>
              <h3>가정의학과 전문의 민혜연 원장</h3>
            </div>
            <ul className="coach-history">
              <li>· 서울대학교 의과대학 졸업</li>
              <li>· 서울대학교병원 가정의학과 수련</li>
              <li>· 전) 서울대병원 가정의학과 임상강사</li>
              <li>· 전) 오가나피부과 원장</li>
              <li>· 전) 소요클리닉 원장</li>
              <li>· 전) 세란병원 피부비만 센터장</li>
              <li>· 현) 에스민 의원 병원장</li>
            </ul>
          </div>
        </SwiperSlide>
      </Swiper>
    </MainComponentLayout>
  );
}
