import { ReactComponent as YellowStarIcon } from 'assets/icons/ver4.0/common/yellow-star.svg';
import ReviewProfile1 from 'assets/images/ver4.0/review/review1-profile.webp';
import Review1 from 'assets/images/ver4.0/review/review1.webp';
import ReviewProfile2 from 'assets/images/ver4.0/review/review2-profile.webp';
import Review2 from 'assets/images/ver4.0/review/review2.webp';
import ReviewProfile3 from 'assets/images/ver4.0/review/review3-profile.webp';
import Review3 from 'assets/images/ver4.0/review/review3.webp';
import ReviewProfile4 from 'assets/images/ver4.0/review/review4-profile.webp';
import Review4 from 'assets/images/ver4.0/review/review4.webp';
import { useMobile } from 'hooks/common/useMobile';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';
import 'swiper/css';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import './Review.scss';

export default function Review() {
  const reviewData = [
    {
      review: {
        image: Review1,
        content:
          '사람마다 잘 맞는 탄수화물이 다르다는 것을 알고 제 몸에 여러가지 실험을 했어요. 햄버거, 마라탕, 오트밀 등 나한테 잘 맞는 음식을 찾게 되니까 스트레스 없이 관리할 수 있다는 점이 정말 좋아요.',
      },
      profile: {
        image: ReviewProfile1,
        name: '양**',
        age: '30대',
        sex: '남성',
      },
    },
    {
      review: {
        image: Review2,
        content:
          '부모님이 당뇨이셔서 정말 걱정이 많았어요. 40대가 넘어가니까 뱃살 나잇살이 잘 안빠지고 혈당도 높아졌는데 글루코핏 앱의 친절한 설명과 의사 코치님 덕분에 관리에 성공했어요.',
      },
      profile: {
        image: ReviewProfile2,
        name: '김**',
        age: '40대',
        sex: '여성',
      },
    },
    {
      review: {
        image: Review3,
        content:
          '건강검진에서 당이 높다는 이야기를 들었어요. 처음에는 정말 공포스러웠는데 글루코핏을 통해서 차근차근 관리하다보니 공복혈당이 10mg/dL 낮아지고 내과선생님에게 칭찬 받았어요!',
      },
      profile: {
        image: ReviewProfile3,
        name: '박**',
        age: '30대',
        sex: '여성',
      },
    },
    {
      review: {
        image: Review4,
        content:
          '정체기가 정말 심했는데 식단이 문제였어요.. 저는 고구마가 혈당 스파이크의 주범!! 오히려 파스타로 바꿨더니 훨씬 낫더라구요! 친구는 반대... 정말 사람마다 잘 맞는 탄수화물이 다른걸 알게 됐어요.',
      },
      profile: {
        image: ReviewProfile4,
        name: '김**',
        age: '40대',
        sex: '여성',
      },
    },
  ];

  const { isMobile } = useMobile(1100);

  const swiperStyle = isMobile
    ? { width: '17.5rem', height: '30rem' }
    : { width: '23rem', height: '35rem' };

  return (
    <MainComponentLayout
      type="gray"
      badgeText="Glucofit Review"
      title={
        <h2>
          +10,000명 이상 <br />
          <span className="badge-highlight">별점 4.98</span> 진짜 후기
        </h2>
      }
    >
      <section className="review-section">
        <Swiper
          slidesPerView="auto"
          modules={[FreeMode]}
          centeredSlides={true}
          freeMode={!isMobile}
          initialSlide={1}
          loop={true}
        >
          {reviewData.map((data, index) => (
            <SwiperSlide key={index} style={swiperStyle}>
              <div className="review-card">
                <div className="review-score">
                  <YellowStarIcon className="yellow-star-icon" />
                  <YellowStarIcon className="yellow-star-icon" />
                  <YellowStarIcon className="yellow-star-icon" />
                  <YellowStarIcon className="yellow-star-icon" />
                  <YellowStarIcon className="yellow-star-icon" />
                </div>
                <p className="review-content">{data.review.content}</p>
                <div className="review-profile">
                  <img
                    className="review-profile-image"
                    src={data.profile.image}
                    alt="후기 작성자 프로필"
                    loading="lazy"
                  />
                  <div className="review-profile-info">
                    <div className="review-profile-name">
                      {data.profile.name} 님의 후기
                    </div>
                    <div className="review-profile-age">
                      {data.profile.age} {data.profile.sex}
                    </div>
                  </div>
                </div>
                <img
                  className="review-image"
                  src={data.review.image}
                  alt="리뷰 이미지"
                  loading="lazy"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </MainComponentLayout>
  );
}
