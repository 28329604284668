import { ReactComponent as ChannelTalkIcon } from 'assets/icons/ver4.0/common/channel-talk.svg';
import Footer from 'components/Footer/Footer';
import Nav from 'components/Nav/Nav';
import { useMobile } from 'hooks/common/useMobile';
import Article from 'pages/Main4.0/Article/Article';
import Banner from 'pages/Main4.0/Banner/Banner';
import Benefit from 'pages/Main4.0/Benefit/Benefit';
import Coach from 'pages/Main4.0/Coach/Coach';
import FaQ from 'pages/Main4.0/FaQ/FaQ';
import Introduction from 'pages/Main4.0/Introduction/Introduction';
import Question from 'pages/Main4.0/Question/Question';
import Report from 'pages/Main4.0/Report/Report';
import Review from 'pages/Main4.0/Review/Review';
import SpecialPlan from 'pages/Main4.0/SpecialPlan/SpecialPlan';
import Support from 'pages/Main4.0/Support/Support';
import Worry from 'pages/Main4.0/Worry/Worry';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import './Main.scss';

export default function Main() {
  const navigate = useNavigate();
  const { isMobile } = useMobile(1100);

  //접근
  const initCheckout = () => {
    if (
      [
        'drdinho',
        'drlee',
        'hwalmyeong',
        'hafeteria',
        'salb',
        'lare',
        'reviewmachine',
        'doctorleetv',
        'mktv',
        'coupon0801',
      ].includes(referralUrl)
    ) {
      navigate(referralInvitePath);
    } else if (
      localStorage.getItem('inviteCode') != 'null' &&
      localStorage.getItem('inviteCode') != null
    ) {
      navigate(`/info?code=${localStorage.getItem('inviteCode')}`);
    } else {
      navigate('/info');
    }
    localStorage.clear();
    window.gtag('event', 'conversion', {
      send_to: 'AW-10992256925/KpgjCL7zqKwYEJ2Pwvko',
    });
    window.fbq('track', 'InitiateCheckout');
  };

  // 제휴 대응
  const [referralUrl, setReferralUrl] = useState(null);
  const [referralInvitePath, setReferralInvitePath] = useState(null);
  useEffect(() => {
    const afterProtocol = window.location.href.split('://')[1];
    const parts = afterProtocol.split('/');
    const subdomain = parts[1];

    setReferralUrl(subdomain);
    console.log('subdomain...', subdomain);

    if (subdomain === 'drdinho') {
      setReferralInvitePath('/info?coupon_number=drdinho');
    } else if (subdomain === 'drlee') {
      setReferralInvitePath('/info?coupon_number=drlee');
    } else if (subdomain === 'hwalmyeong') {
      setReferralInvitePath('/info?coupon_number=hwalmyeong');
    } else if (subdomain === 'hafeteria') {
      setReferralInvitePath('/info?coupon_number=hafeteria');
    } else if (subdomain === 'salb') {
      setReferralInvitePath('/info?coupon_number=salb');
    } else if (subdomain === 'lare') {
      setReferralInvitePath('/info?coupon_number=lare');
    } else if (subdomain === 'reviewmachine') {
      setReferralInvitePath('/info?coupon_number=reviewmachine');
    } else if (subdomain === 'doctorleetv') {
      setReferralInvitePath('/info?coupon_number=doctorleetv');
    } else if (subdomain === 'mktv') {
      setReferralInvitePath('/info?coupon_number=mktv');
    } else if (subdomain === 'coupon0801') {
      setReferralInvitePath('/info?coupon_number=coupon0801');
    }
  }, []);

  const tagManagerArgs = {
    gtmId: 'GTM-57FDQMS',
  };
  useEffect(() => {
    localStorage.clear();
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <container id="container" style={{ position: 'relative' }}>
      <Nav initCheckout={initCheckout} />
      <Banner initCheckout={initCheckout} />
      <Introduction />
      <Coach />
      <Worry />
      <Support />
      <Benefit />
      <Question />
      <Report />
      <Review />
      <SpecialPlan initCheckout={initCheckout} />
      <Article />
      <FaQ />
      <CannelTalk isMobile={isMobile} />
      <MobileStartButton isMobile={isMobile} initCheckout={initCheckout} />
      <Footer />
    </container>
  );
}

function CannelTalk({ isMobile }) {
  const style = isMobile
    ? { width: 44, height: 44 }
    : { width: 56, height: 56 };
  return (
    <a
      href="https://glucofit.channel.io"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ChannelTalkIcon
        style={{
          position: 'fixed',
          bottom: 50,
          right: 20,
          zIndex: 1000,
          ...style,
        }}
      />
    </a>
  );
}

function MobileStartButton({ isMobile, initCheckout }) {
  return (
    isMobile && (
      <button className="strong" id="floating-button" onClick={initCheckout}>
        지금 시작하기
      </button>
    )
  );
}
