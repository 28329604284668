import { ReactComponent as GlucofitLogo } from 'assets/icons/ver4.0/common/glucofit-logo-black.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/ver4.0/common/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/ver4.0/common/youtube.svg';
import './Footer.scss';

export default function Footer() {
  return (
    <footer className="footer-section">
      <div className="footer-top">
        <div className="logo">
          <GlucofitLogo />
        </div>
        <ul className="footer-navigation">
          <li>
            <a
              href="https://glucofit.co.kr"
              target="_blank"
              rel="noopener noreferrer"
            >
              건강 매거진
            </a>
          </li>
          <li>
            <a
              href="https://connect.glucofit.co.kr"
              target="_blank"
              rel="noopener noreferrer"
            >
              글루코핏 커넥트
            </a>
          </li>
          <li>
            <a
              href="https://glucofit.channel.io/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              고객센터
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-left">
          <h3>주식회사 랜식</h3>
          <div>
            대표자 : 양혁용
            <br className="mobile" />
            서울특별시 서초구 사임당로8길 13, 제일빌딩 402-J281호
          </div>
          <div>사업자 등록 번호: 253-87-02471</div>
          <div>통신판매 신고번호 : 2024-서울서초-1164</div>
          <div>의료기기판매업 신고번호: 제2024-3210034-00150호</div>
          <div>연락처: 1551-6061</div>
          <div className="footer-all-right-reserved">
            © Lansik Inc. All Rights Reserved.
          </div>
          <ul className="footer-information">
            <div>
              <li>이용약관</li>
              <li>개인정보처리방침</li>
              <li>이용안내</li>
            </div>
            <div>
              <li>
                <a
                  href="https://bit.ly/3UZXaxr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  제휴문의
                </a>
              </li>
            </div>
          </ul>
        </div>
        <div className="footer-bottom-right">
          <a
            href="https://instagram.com/glucofit_official"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className="instagram-icon" />
          </a>
          <a
            href="https://youtube.com/@glucofit_health"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon className="youtube-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
}
