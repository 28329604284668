import Phone from 'assets/images/ver3.0/img-start-phone.png';
import Plus from 'assets/images/ver3.0/img-start-plus.png';
import Sensor from 'assets/images/ver3.0/img-start-sensor.png';
import '../CSS/SpecialEvent.scss';

export default function SpecialEvent({ navigate }) {
  return (
    <div className="start-container">
      <div className="start-title-eng strong">Special Event</div>
      <div className="start-title">
        지금 <span className="strong">글루코핏</span>을 <br />
        <span className="strong">경험</span>해보세요
      </div>
      <div className="start-content-container">
        <div className="start-content first">
          <div className="start-content-img-phone">
            <img src={Phone} alt="글루코핏 1년 멤버십, 센서4개 월 54000원" />
          </div>
          <div className="start-content-info">
            <div className="start-best-text">
              <span className="start-best-icon strong">BEST</span>
              글루코핏 스타터 패키지
            </div>
            <div className="start-month-price">
              <span className="strong">유누즈 공구 이벤트</span>
            </div>
            <div className="start-content-description">
              <div>
                글루코핏 시작시 <br />
                <span className="strong">센서가 필수</span>로 필요해요
              </div>
            </div>
          </div>
          <div className="start-plus-icon">
            <img src={Plus} alt="추가" />
          </div>
        </div>
        <div className="start-content second">
          <div className="start-content-img-sensor">
            <img src={Sensor} alt="무채혈 연속혈당측정 센서" />
          </div>
          <div className="start-content-info">
            <div className="start-content-title">
              <div className="sensor-badge">2주분</div>
              글루코핏 스타터 패키지
            </div>
            <div className="start-content-info-sensor">
              <span className="green strong">무채혈</span>연속혈당측정 센서
            </div>
            <div className="start-content-description">
              <div>
                <span className="strong">아픈 채혈과정 없이</span> 간편하게
                <br />
                혈당을 측정해보세요
              </div>
            </div>
          </div>
          <button className="start-apply-button strong" onClick={navigate}>
            신청하기
          </button>
        </div>
      </div>
    </div>
  );
}
