import { ReactComponent as RightArrowIcon } from 'assets/icons/ver4.0/banner/button-right-arrow.svg';
import { ReactComponent as GlucofitLogo } from 'assets/icons/ver4.0/banner/gradient-glucofit-logo.svg';
import { ReactComponent as YellowStarIcon } from 'assets/icons/ver4.0/common/yellow-star.svg';
import BannerDesktop from 'assets/images/ver4.0/banner/banner.desktop.webp';
import BannerTablet from 'assets/images/ver4.0/banner/banner.tablet.webp';
import './Banner.scss';

export default function Banner({ initCheckout }) {
  return (
    <section className="banner-section">
      <div className="banner-content">
        <BannerReview />
        <BannerTitle />
        <BannerDescription />
        <BannerButton onClick={initCheckout} />
      </div>
      <div className="banner-image">
        <picture>
          <source media="(max-width: 1200px)" srcSet={BannerTablet} />
          <img
            src={BannerDesktop}
            alt="국내 최초 의사가 만든 혈당 케어 글루코핏"
            loading="lazy"
          />
        </picture>
      </div>
    </section>
  );
}

function BannerReview() {
  return (
    <div className="banner-review">
      <YellowStarIcon className="yellow-star-icon" />
      <YellowStarIcon className="yellow-star-icon" />
      <YellowStarIcon className="yellow-star-icon" />
      <YellowStarIcon className="yellow-star-icon" />
      <YellowStarIcon className="yellow-star-icon" />
      &nbsp; 별점 4.98 &#183; 10,000+ 회원
    </div>
  );
}

function BannerTitle() {
  return (
    <h1 className="banner-title-text">
      국내 최초 의사가 만든 혈당 케어
      <GlucofitLogo />
    </h1>
  );
}

function BannerDescription() {
  return (
    <p className="banner-description-text">
      글루코핏을 통해
      <br className="mobile" /> 내 몸의 혈당 스파이크를 확인하고
      <br />
      전문적으로 관리해보세요
    </p>
  );
}

function BannerButton({ onClick }) {
  return (
    <button className="banner-button" onClick={onClick}>
      지금 시작하기 <RightArrowIcon />
    </button>
  );
}
