import SupportMobile from 'assets/images/ver4.0/support/support-mobile.webp';
import SupportWeb from 'assets/images/ver4.0/support/support-web.webp';
import MainComponentLayout from 'pages/Main4.0/Layout/MainComponentLayout';

import './Support.scss';

export default function Support() {
  return (
    <MainComponentLayout type="gray">
      <div className="support-section">
        <div className="support-description">
          <div className="support-badge">We Support You</div>
          <h2>
            <span className="badge-highlight">혈당 관리, </span> 글루코핏이
            <br />
            도와드릴게요
          </h2>
          <p>
            어떤 음식을 먹으면 혈당 스파이크가 <br />
            발생하는지 확인하고 더 똑똑하고 건강하게 <br />
            혈당을 관리해보세요
          </p>
        </div>
        <div className="support-image">
          <picture>
            <source media="(max-width: 1100px)" srcSet={SupportMobile} />
            <img
              src={SupportWeb}
              alt="혈당 관리, 글루코핏이 도와드릴게요"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </MainComponentLayout>
  );
}
